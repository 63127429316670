import { ColumnDef, Row } from '@tanstack/react-table';
import { toMaskByString } from '@/components/form';
import { cn, compileFullName } from '@/lib/utils';
import { Trash2 } from 'lucide-react';
import { Client, Guardian } from '../interfaces/guardians.interface';
import { Chips } from '@/components/common';
import { Colors } from '@/common/enums';

type Params = {
  t: any;
  onConfirmRemove: (row: Row<Guardian>) => void;
  onPreviewClient: (client: Client) => void;
  isCanRemove?: boolean;
};

export const getAllGuardiansColumns = ({
  t,
  onConfirmRemove,
  onPreviewClient,
  isCanRemove = true,
}: Params): ColumnDef<Guardian>[] => {
  const columns: ColumnDef<Guardian>[] = [
    {
      id: 'Full Name',
      accessorFn: (row) => `${row.firstName || ''} ${row.lastName || ''}`,
      header: () => <span>{t('Tables.fullName')}</span>,
      size: 250, //starting column size
      enableSorting: true,
      enablePinning: false,
    },
    {
      id: 'Email',
      accessorKey: 'email',
      header: () => <span>{t('Tables.email')}</span>,
      enableSorting: false,
      enablePinning: false,
      size: 250,
    },
    {
      id: 'Primary Number',
      accessorKey: 'primaryNumber',
      accessorFn: (row) => toMaskByString(row.primaryNumber),
      header: () => <span>{t('Tables.primaryNumber')}</span>,
      enableSorting: false,
      enablePinning: false,
      size: 170,
    },
    {
      id: 'Secondary Number',
      accessorFn: (row) => toMaskByString(row.secondaryNumber),
      header: () => <span>{t('Tables.secondaryNumber')}</span>,
      enableSorting: false,
      enablePinning: false,
      size: 170,
    },
    {
      id: 'Primary Relationship',
      header: () => <span>{t('Tables.primaryRelationship')}</span>,
      accessorKey: 'primaryRelationship',
      enableSorting: false,
      enablePinning: false,
      size: 170,
    },
    {
      id: 'Secondary Relationship',
      header: () => <span>{t('Tables.secondaryRelationship')}</span>,
      accessorKey: 'secondaryRelationship',
      enableSorting: false,
      enablePinning: false,
      size: 185,
    },
    {
      id: 'Languages',
      header: () => <span>{t('Tables.languages')}</span>,
      cell: ({ row }) => {
        if (row.original.languages) {
          return <span className="">{row.original.languages.join(', ')}</span>;
        } else {
          return <span></span>;
        }
      },
      enableSorting: false,
      enablePinning: false,
      size: 100,
    },
    {
      id: 'Clients',
      header: () => <span>{t('Tables.clients')}</span>,
      cell: ({ row }) => {
        if (row.original.clients) {
          return (
            <div className="flex flex-row flex-wrap gap-1 py-2">
              {row.original.clients.map((client) => (
                <Chips
                  onClickAction={(e) => {
                    onPreviewClient(client);
                    e.stopPropagation();
                  }}
                  key={client.id}
                  color={Colors.indigo}
                  title={compileFullName(client)}
                />
              ))}
            </div>
          );
        }
        return <></>;
      },
      enableSorting: false,
      enablePinning: false,
      size: 250,
    },
  ];

  if (isCanRemove) {
    columns.push({
      id: 'Remove',
      enableSorting: false,
      cell: ({ row }) => (
        <div
          className="flex min-h-8 min-w-8 flex-row items-center justify-center"
          onClick={(e) => {
            if (!row.original.deactivatedAt) {
              onConfirmRemove(row);
            }
            e.stopPropagation();
          }}
        >
          <Trash2
            className={cn(
              'w-[18px] cursor-pointer text-gray-400',
              row.original.deactivatedAt && 'cursor-not-allowed opacity-45'
            )}
          />
        </div>
      ),
      size: 50,
      enablePinning: true,
    });
  }
  return columns;
};
