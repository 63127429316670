import { Therapist } from './interfaces/therapists.interface';

export interface ServicePayRate {
  id: string;
  payRate: number;
  serviceRoleName: string;
}
export interface PayRates {
  generalBasePayRate: number;
  payRates: ServicePayRate[];
}

/**
 * Parse the therapist's pay rates into a usable format.
 *
 * @param {Therapist} therapist - The therapist object to parse.
 * @returns {PayRates | null} - The parsed pay rates, or null if no valid data is found.
 */
export const parsePayRates = (therapist: Therapist): PayRates | null => {
  if (therapist.servicePayRate && therapist.servicePayRate.length !== 0) {
    const { generalBasePayRate, payRates } = therapist.servicePayRate.reduce<PayRates>(
      (acc, rate) => {
        if (rate.type === 'default') {
          acc.generalBasePayRate = rate.payRate || 0;
        } else {
          acc.payRates.push({
            id: rate.serviceRoleId as string,
            payRate: rate.payRate,
            serviceRoleName: rate.serviceRoleName as string,
          });
        }
        return acc;
      },
      { generalBasePayRate: 0, payRates: [] }
    );
    return { generalBasePayRate, payRates };
  } else if (therapist.serviceRole) {
    const payRates = therapist.serviceRole?.map((role) => ({
      id: role.id,
      payRate: 0,
      serviceRoleName: role.title,
    }));
    return {
      generalBasePayRate: 0,
      payRates,
    };
  }
  return null;
};
