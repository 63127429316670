import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { EditShiftValidationSchema } from '@/views/all-shifts/manage/edit-shift-validation-schema';
import { RecurringShiftData } from '@/views/all-shifts/manage/recurring-shift-popover';
import { RecurringShiftValidationSchema } from '@/views/all-shifts/manage/recurring-shift-validation-schema';
import { DefaultError, UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query';
import dayjs from '@/lib/dayjsConfig';
import * as _ from 'lodash';
import { SHIFT_REPEAT_TYPES } from '@/common/enums';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

export interface Request extends RecurringShiftValidationSchema, EditShiftValidationSchema, RecurringShiftData {
  id: string;
}

export type Options = Omit<UseMutationOptions<string, DefaultError, Request>, 'mutationKey' | 'mutationFn'>;

export const useUpdateShiftMutation = (options?: Options) => {
  const queryClient = useQueryClient();
  const _mutation = async (value: Request): Promise<string> => {
    const { notes, date, endTime, startTime, clockIn, clockOut } = value;

    const shiftOffset = dayjs(date).utcOffset();
    const currentOffset = dayjs().utcOffset();
    const offsetDifference = currentOffset - shiftOffset;
    const startTime24h = dayjs(startTime, 'hh:mm A');
    const endTime24h = dayjs(endTime, 'hh:mm A');
    const clockInTime24h = clockIn ? dayjs(clockIn.toUpperCase(), 'hh:mm A') : null;
    const clockOutTime24h = clockOut ? dayjs(clockOut.toUpperCase(), 'hh:mm A') : null;

    const parsedValue = {
      startAt: dayjs(date)
        .set('hour', startTime24h.hour())
        .set('minute', startTime24h.minute())
        // .subtract(offsetDifference, 'minute')
        .toISOString(),
      endAt: dayjs(date)
        .set('hour', endTime24h.hour())
        .set('minute', endTime24h.minute())
        // .subtract(offsetDifference, 'minute')
        .toISOString(),
      clockIn: clockInTime24h
        ? dayjs(date)
            .set('hour', clockInTime24h.hour())
            .set('minute', clockInTime24h.minute())
            // .subtract(offsetDifference, 'minute')
            .toISOString()
        : null,
      clockOut: clockOutTime24h
        ? dayjs(date)
            .set('hour', clockOutTime24h.hour())
            .set('minute', clockOutTime24h.minute())
            // .subtract(offsetDifference, 'minute')
            .toISOString()
        : null,
      notes: notes && notes !== '' ? notes : null,
      recurrenceType: value.configureRepeat,
      weekOrMonthDay:
        value.configureRepeat === SHIFT_REPEAT_TYPES.SINGLE
          ? 0
          : value.configureRepeat === SHIFT_REPEAT_TYPES.WEEKLY
            ? value.repeatOnWeek
            : parseInt(value.repeatOnDay as string),
      lastRepeatDate: value.endsOn,
    };
    const body = _.omitBy(parsedValue, (v) => v === '');
    const { data } = await axiosInterceptorInstance.patch(`shifts/${value.id}`, body);
    return data;
  };

  return useMutation({
    mutationKey: ['shifts'],
    mutationFn: _mutation,
    onSettled: (data, error) => {
      if (!error) {
        queryClient.invalidateQueries({ queryKey: ['services'] });
        queryClient.invalidateQueries({ queryKey: ['clients'] });
        queryClient.invalidateQueries({ queryKey: ['dashboard'] });
        queryClient.invalidateQueries({ queryKey: ['therapists'] });
        queryClient.invalidateQueries({ queryKey: ['shifts'] });
      }
    },
    ...options,
  });
};
