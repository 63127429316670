import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { transformViewDateToApiDate } from '@/lib/utils';
import { DefaultError, UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query';

type Request = {
  serviceId: string;
  authorizedTo: string;
};

export type Options = Omit<UseMutationOptions<string, DefaultError, Request>, 'mutationKey' | 'mutationFn'>;

export const useReinstateServiceMutation = (options?: Options) => {
  const queryClient = useQueryClient();
  const _mutation = async ({ serviceId, authorizedTo }: Request): Promise<string> => {
    const { data } = await axiosInterceptorInstance.patch(`services/${serviceId}/reinstate`, {
      authorizedTo: transformViewDateToApiDate(authorizedTo),
    });
    return data;
  };

  return useMutation({
    mutationKey: ['services', 'reinstate'],
    onSettled: (data, error) => {
      if (!error) {
        queryClient.resetQueries({ queryKey: ['services'] });
        queryClient.invalidateQueries({ queryKey: ['clients'] });
        queryClient.invalidateQueries({ queryKey: ['therapists'] });
        queryClient.invalidateQueries({ queryKey: ['activity-logs'] });
      }
    },
    mutationFn: _mutation,
    ...options,
  });
};
