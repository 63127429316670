'use client';
import { Button } from '@/components/ui';
import Folder from './../../../../public/icons/folder-2.svg';
import { DialogFooter } from '@/components/ui/dialog';
import { cn, compileFullName, compileLastName, convertHoursToTime, getColorForServiceRole } from '@/lib/utils';
import { useTranslations } from 'next-intl';
import { Therapist } from '../interfaces/therapists.interface';
import { toMaskByString } from '@/components/form';
import { Chips, LabelValueItem, ScrollArea, toast, ToastTypeEnums, StatisticBadge } from '@/components/common';
import Calendar from './../../../../public/icons/calendar.svg';
import { PERMISSIONS_TYPES } from '@/lib/RBAC/enums/permissions-types';
import { useIsAllowed } from '@/lib/RBAC';
import { UserTypes, useUserResendLinkMutation } from '@/hooks/fetchers/mutations/useUserResendLinkMutation';
import { PreviewTherapistDialog, PreviewTherapistDialogRef } from './preview-therapist.dialog';
import { createRef } from 'react';
import { USER_ROLES_COLORS } from '@/common/constants';
import { ROLE_TYPES } from '@/lib/RBAC/enums/role-types';
import { ROLE_CHANGE_REASONS } from '@/common/enums';
import { TriangleAlert } from 'lucide-react';

interface Props {
  className?: string;
  therapist?: Therapist;
  onEdit: () => void;
  onRefresh?: () => void;
}

export function TherapistProfileTab({ therapist, className, onEdit, onRefresh }: Props) {
  const t = useTranslations();
  const { checkPermissions } = useIsAllowed();

  const previewTherapistDialogRef = createRef<PreviewTherapistDialogRef>();

  const { mutate: resendLink, isPending } = useUserResendLinkMutation({
    onSuccess: () => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
      });
      onRefresh?.();
    },
  });

  const previewTherapist = (id: string) => {
    previewTherapistDialogRef.current?.openById(id);
  };

  let limitReason;
  if (therapist && therapist.limitationData) {
    switch (therapist.limitationData.reason) {
      case ROLE_CHANGE_REASONS.START_OF_PROBATION:
        limitReason = t('Common.START_OF_PROBATION_PREVIEW');
        break;
      case ROLE_CHANGE_REASONS.LIMITED_BY_ADMIN:
        limitReason = t('Common.LIMITED_BY_ADMIN_PREVIEW');
        break;
      case ROLE_CHANGE_REASONS.LOW_PARTICIPATION:
        limitReason = t('Common.LOW_PARTICIPATION_PREVIEW');
        break;
      case ROLE_CHANGE_REASONS.POOR_SERVICE_QUALITY:
        limitReason = t('Common.POOR_SERVICE_QUALITY_PREVIEW');
        break;
      case ROLE_CHANGE_REASONS.PAST_DUE_REPORTS:
        limitReason = therapist?.role === ROLE_TYPES.Assistant || therapist?.role === ROLE_TYPES['Assistant Limited'] ? t('Common.PAST_DUE_REPORTS_PREVIEW_ASSISTANT') : t('Common.PAST_DUE_REPORTS_PREVIEW_THERAPIST');
        break;
      case ROLE_CHANGE_REASONS.OVERTIME:
        limitReason = t('Common.OVERTIME_PREVIEW');
        break;
      default:
        break;
    }
  }

  return (
    <div>
      <div className="relative max-w-[1100px]">
        <div className="absolute right-0 top-0 flex flex-col items-center space-y-2">
          <StatisticBadge
            className="min-w-72"
            svgPath={Calendar}
            label={t('Pages.Dashboard.hoursWorked')}
            value={`${therapist?.hoursProvided ? convertHoursToTime(therapist.hoursProvided) : '0h'} / ${therapist?.hoursPlanned ? convertHoursToTime(therapist.hoursPlanned) : '0h'}`}
          />

          <StatisticBadge
            className="min-w-72"
            svgPath={Folder}
            label={therapist?.role === ROLE_TYPES.Assistant || therapist?.role === ROLE_TYPES['Assistant Limited'] ? t('Pages.Dashboard.notesDue') : t('Pages.Dashboard.reportDue')}
            value={therapist?.pastDueDocumentsCount ?? 0}
          />

          {limitReason && (
            <div className="flex min-w-72 flex-row items-center rounded-md border border-gray-200">
              <TriangleAlert className="ml-2 mr-3 size-8 rounded-md bg-blue-50 p-1 text-red-400" />
              <div className="flex w-[189px] flex-col p-2">
                <span className="break-words text-sm font-normal text-gray-500">{limitReason}</span>
              </div>
            </div>
          )}
        </div>

        <ScrollArea className={cn('mr-[-25px] h-[calc(100vh-364px)] overflow-auto', className)}>
          <div className="space-y-4">
            <h2 className="mb-2 mt-4 text-sm font-bold text-gray-950">{t('Common.personalInformation')}</h2>

            <div className="grid grid-cols-form-cols-2 gap-8 pl-1">
              <LabelValueItem label={t('Forms.firstName.label')} value={therapist?.firstName} />
              <LabelValueItem label={t('Forms.lastName.label')} value={compileLastName(therapist)} />

              {therapist?.supervisor && (
                <LabelValueItem label={t('Forms.supervisor.label')} withoutBorder>
                  <Chips
                    color={USER_ROLES_COLORS[ROLE_TYPES.Therapist]}
                    title={compileFullName(therapist.supervisor)}
                    onClickAction={
                      checkPermissions([PERMISSIONS_TYPES.PREVIEW_THERAPIST_PROFILE])
                        ? () => previewTherapist(therapist.supervisor?.id as string)
                        : undefined
                    }
                  />
                </LabelValueItem>
              )}
              <LabelValueItem label={t('Forms.accessLevel.label')} value={therapist?.role} />
            </div>

            <div className="mb-6 flex w-full max-w-[676px] flex-col space-y-6">
              <LabelValueItem
                label={t('Forms.speakingLanguage.label')}
                value={
                  therapist && therapist.languages && therapist.languages.length > 0
                    ? therapist.languages.join(', ')
                    : undefined
                }
              />

              <LabelValueItem label={t('Forms.serviceRole.label')}>
                <div className="flex flex-wrap">
                  {therapist &&
                    therapist.serviceRole &&
                    therapist.serviceRole.map((role) => (
                      <Chips
                        className="mr-1"
                        key={role.id}
                        title={role.title}
                        color={getColorForServiceRole(role.title, role.speciality?.code)}
                      />
                    ))}
                </div>
              </LabelValueItem>
            </div>

            <h2 className="my-6 text-sm font-bold text-gray-950">{t('Common.contactInformation')}</h2>

            <div className="grid grid-cols-form-cols-2 gap-8 pl-1">
              <LabelValueItem label={t('Forms.email.label')} forceLineBreak value={therapist?.email} />
              <LabelValueItem
                label={t('Forms.phoneNumber.label')}
                value={therapist && toMaskByString(therapist.phone)}
              />
              {checkPermissions([PERMISSIONS_TYPES.VIEW_ADP_ID]) && (
                <LabelValueItem
                  label={`${t('Forms.adpId.label')} ${therapist?.adpEmployeeName ? '(' + therapist?.adpEmployeeName + ')' : ''}`}
                  value={therapist?.adpId}
                />
              )}
            </div>
          </div>
        </ScrollArea>
      </div>

      <DialogFooter className="mt-10 flex h-20 flex-row items-center justify-end space-x-2 border-t border-t-gray-300">
        {!therapist?.deactivatedAt && (
          <>
            {checkPermissions([PERMISSIONS_TYPES.RESEND_INVITATION_LINK]) && !therapist?.isAuthorizationFinished ? (
              <Button
                disabled={isPending}
                type="button"
                size="lg"
                variant="outline"
                className="mt-5"
                onClick={() => resendLink({ id: therapist?.id as string, type: UserTypes.THERAPIST })}
              >
                {t('Buttons.resendLink')}
              </Button>
            ) : null}
            {checkPermissions([PERMISSIONS_TYPES.MANAGE_THERAPIST_DETAILS]) ? (
              <Button type="button" size="lg" className="mt-5" onClick={onEdit}>
                {t('Buttons.edit')}
              </Button>
            ) : null}
          </>
        )}
      </DialogFooter>
      <PreviewTherapistDialog onUpdated={onRefresh} ref={previewTherapistDialogRef} />
    </div>
  );
}
