'use client';
import {
  ColumnOrderState,
  getCoreRowModel,
  getSortedRowModel,
  Row,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { ChangeEvent, createRef, useEffect, useRef, useState } from 'react';
import { Plus } from 'lucide-react';
import {
  ToggleFilter,
  ConfirmDialogRef,
  ColumnAdvance,
  TableColumnSettings,
  ConfirmDialog,
  Pagination,
  toast,
  ToastAction,
  ToastTypeEnums,
} from '@/components/common';
import { Button } from '@/components/ui';
import { useTranslations } from 'next-intl';
import { clearFilter, parseFilterToArrayOfTagsData, CommonTable, SimpleSearch, TableFilter } from '@/components/common';
import { AllClientsFilterForm, FilterAllClientsParams } from '../header-filter/all-shifts-filter-form';
import { Client, Service, Shift, Therapist } from '../interfaces/shift.interface';
import { useIsAllowed } from '@/lib/RBAC';
import { PERMISSIONS_TYPES } from '@/lib/RBAC/enums/permissions-types';
import { PreviewShiftDialog, PreviewShiftDialogRef } from '../preview/preview-shift.dialog';
import { getAllShiftsColumns } from './all-shifts-columns';
import { useShiftsQuery } from '@/hooks/fetchers/queries/shifts/useShiftsQuery';
import { useRemoveUndoShiftMutation } from '@/hooks/fetchers/mutations/shifts/useRemoveUndoShiftMutation';
import { useRemoveShiftMutation } from '@/hooks/fetchers/mutations/shifts/useRemoveShiftMutation';
import { ManageShiftDialog, ManageShiftDialogRef } from '../manage/manage-shift.dialog';
import { cn } from '@/lib/utils';
import { TABLE_TYPES } from '@/common/enums';
import { useTableSettingsQuery } from '@/hooks/fetchers/queries/useTableSettingsQuery';
import { useTableSettingsMutation } from '@/hooks/fetchers/mutations/useTableSettingsMutation';
import { PreviewClientDialog, PreviewClientDialogRef } from '@/views/all-clients/preview/preview-client.dialog';
import { PreviewServiceDialog, PreviewServiceDialogRef } from '@/views/all-services/preview/preview-service.dialog';
import {
  PreviewTherapistDialog,
  PreviewTherapistDialogRef,
} from '@/views/all-therapists/preview/preview-therapist.dialog';
import { useTableFilterDefaultQuery } from '@/hooks/fetchers/queries/useTableFilterDefaultQuery';
import { useTableFilterDefaultMutation } from '@/hooks/fetchers/mutations/useTableFilterDefaultMutation';
import { allShiftsSortingHelper } from './all-shifts-sorting-helper';

const PER_PAGE = 100;

interface Params {
  clientId?: string;
  therapistId?: string;
  serviceId?: string;
  tableClassName?: string;
  hideCreate?: boolean;
  hideTableSettings?: boolean;
}

export function AllShiftsTable({
  clientId,
  therapistId,
  serviceId,
  tableClassName,
  hideCreate,
  hideTableSettings,
}: Params) {
  const t = useTranslations();
  const [data, setData] = useState<Shift[]>([]);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnVisibility, setColumnVisibility] = useState({});
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);
  const [filter, setFilter] = useState<FilterAllClientsParams>({});
  const [search, setSearch] = useState('');
  const [showSigned, setShowSigned] = useState(true);
  const [pagination, setPagination] = useState<{ page: number; perPage: number }>({
    page: 0,
    perPage: PER_PAGE,
  });
  const previewShiftDialogRef = createRef<PreviewShiftDialogRef>();
  const previewTherapistDialogRef = createRef<PreviewTherapistDialogRef>();
  const previewClientDialogRef = createRef<PreviewClientDialogRef>();
  const previewServiceDialogRef = createRef<PreviewServiceDialogRef>();

  const manageShiftDialogRef = createRef<ManageShiftDialogRef>();
  const confirmDialogRef = useRef<ConfirmDialogRef>();
  const { checkPermissions } = useIsAllowed();

  const { data: tableConfig } = useTableSettingsQuery(TABLE_TYPES.ALL_SHIFTS);
  const { mutate: setTableConfig } = useTableSettingsMutation();
  const { data: tableDefFilters } = useTableFilterDefaultQuery(TABLE_TYPES.ALL_SHIFTS);
  const { mutate: setTableDefFilters } = useTableFilterDefaultMutation();
  const {
    data: tableData,
    refetch: refetchShifts,
    isLoading,
  } = useShiftsQuery({
    skip: pagination.page * pagination.perPage,
    take: pagination.perPage,
    clientId: clientId,
    serviceId: serviceId,
    therapistId: therapistId,
    search,
    isSigned: showSigned,
    ...filter,
    ...allShiftsSortingHelper(sorting),
  });

  useEffect(() => {
    if (tableConfig && tableConfig.settings.length > 0) {
      table.getAllLeafColumns().map((c: ColumnAdvance<Shift>) => {
        const isVisible = tableConfig.settings.find((col) => col.title === c.id)?.isVisible;
        c.toggleVisibility(isVisible); // Change visible columns
      });
      table.setColumnOrder(tableConfig.settings.map((col) => col.title)); // Change order columns by ids
    }
  }, [JSON.stringify(tableConfig)]);

  useEffect(() => {
    if (tableData) {
      setData(tableData?.data);
    }
  }, [tableData]);

  useEffect(() => {
    setPagination((prev) => ({ ...prev, page: 0 }));
  }, [JSON.stringify(filter), showSigned]);

  const { mutate: removeShift } = useRemoveShiftMutation({
    onSuccess: (data) => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
        description: t('Toasts.successDeleted'),
        action: (
          <ToastAction onClick={() => removeUndoClient(data.id)} altText="Cancel remove">
            Undo action
          </ToastAction>
        ),
      });
      refetchShifts();
    },
  });

  const { mutate: removeUndoClient } = useRemoveUndoShiftMutation({
    onSuccess: () => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
        description: t('Toasts.successRestored'),
      });
      refetchShifts();
    },
  });

  const handleConfirmRemove = (row: Row<Shift>) => {
    confirmDialogRef.current?.open({
      description: t('Toasts.confirmDeleteShift'),
      value: row.original.id,
    });
  };

  const handlePreviewClient = (client: Client) => {
    previewClientDialogRef.current?.openById(client.id);
  };

  const handlePreviewService = (service: Service) => {
    previewServiceDialogRef.current?.openById(service.id);
  };

  const handlePreviewTherapist = (therapist: Therapist) => {
    previewTherapistDialogRef.current?.openById(therapist.id);
  };

  const table = useReactTable({
    columns: getAllShiftsColumns({
      t,
      checkPermissions,
      onPreviewClient: handlePreviewClient,
      onPreviewService: handlePreviewService,
      onPreviewTherapist: handlePreviewTherapist,
      onConfirmRemove: handleConfirmRemove,
    }),
    data,
    debugTable: false,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(), //client-side sorting
    onSortingChange: setSorting, //optionally control sorting state in your own scope for easy access
    onColumnVisibilityChange: setColumnVisibility,
    onColumnOrderChange: setColumnOrder,
    manualSorting: true,
    state: {
      sorting,
      columnVisibility,
      columnOrder,
    },
  });

  const handleChangeColumnSettings = (columnIds: ColumnAdvance<Shift>[]) => {
    setTableConfig({
      name: TABLE_TYPES.ALL_SHIFTS,
      settings: columnIds.map((col) => ({ isVisible: col.isVisible, title: col.id })) as {
        isVisible: boolean;
        title: string;
      }[],
    });
    const ids = columnIds.map((c) => {
      c.toggleVisibility(c.isVisible); // Change visible columns
      return c.id;
    });
    table.setColumnOrder(ids); // Change order columns by ids
  };

  useEffect(() => {
    if (tableDefFilters && tableDefFilters.filters) {
      setFilter(tableDefFilters.filters);
    }
  }, [JSON.stringify(tableDefFilters)]);

  const handleSearchFilter = (event?: ChangeEvent<HTMLInputElement>) => {
    const search = event?.target.value || '';
    setPagination((prev) => ({
      ...prev,
      page: 0,
    }));
    setSearch(search);
  };

  const columnsIds = table.getAllLeafColumns().map((column: ColumnAdvance<Shift>) => {
    column.isVisible = column.getIsVisible();
    return column;
  });

  return (
    <>
      <header className="flex flex-row items-center justify-between py-2">
        <TableFilter
          filter={filter}
          clearFilter={clearFilter}
          parseFilterToArrayOfTagsData={parseFilterToArrayOfTagsData}
          onUpdateFilter={setFilter}
          onSaveAsDefaultFilter={(filters) => {
            setTableDefFilters({
              name: TABLE_TYPES.ALL_SHIFTS,
              filters,
            });
          }}
          formComponent={AllClientsFilterForm}
        />

        <div className="flex flex-row items-center space-x-2">
          <SimpleSearch onSearchChange={handleSearchFilter} />
          {!hideCreate && checkPermissions([PERMISSIONS_TYPES.ADD_SHIFTS]) ? (
            <Button size="sm" onClick={() => manageShiftDialogRef.current?.open()}>
              <Plus className="mr-1 size-4" /> {t('Buttons.addShift')}
            </Button>
          ) : null}
          {!hideTableSettings && <TableColumnSettings columnsIds={columnsIds} onChange={handleChangeColumnSettings} />}
        </div>
      </header>

      <div className="mb-2 grid h-10 w-full max-w-60 grid-cols-2 gap-1">
        <ToggleFilter label={t('Buttons.signed')} onChange={() => setShowSigned(true)} isActive={showSigned} />
        <ToggleFilter label={t('Buttons.unsigned')} onChange={() => setShowSigned(false)} isActive={!showSigned} />
      </div>

      <div className={cn('relative h-[calc(100vh-296px)] max-w-full overflow-auto', tableClassName)}>
        <div className="absolute w-full">
          <CommonTable
            table={table}
            onClickByRow={(rowOriginal) => previewShiftDialogRef.current?.openById(rowOriginal.id)}
          />
        </div>
      </div>

      {tableData && tableData.totalCount > 0 && (
        <Pagination
          className="mt-1"
          changeCurrentPage={(page) =>
            setPagination((prev) => {
              return {
                ...prev,
                page: page - 1,
              };
            })
          }
          totalSize={tableData && tableData.totalCount ? tableData.totalCount : 0}
          sizePerPage={pagination.perPage}
          currentPage={pagination.page + 1}
        />
      )}

      {isLoading && (
        <div className="flex h-9 w-full items-center justify-center">
          <span className="mt-1 text-sm text-gray-400">{t('Common.loadingWait')}</span>
        </div>
      )}

      <ConfirmDialog
        ref={confirmDialogRef}
        onConfirm={(id) => removeShift(id)}
        title={t('Common.deleteShift')}
        type="delete"
      />
      <PreviewClientDialog onUpdated={refetchShifts} ref={previewClientDialogRef} />
      <PreviewServiceDialog onUpdated={refetchShifts} ref={previewServiceDialogRef} />
      <PreviewTherapistDialog onUpdated={refetchShifts} ref={previewTherapistDialogRef} />

      <PreviewShiftDialog ref={previewShiftDialogRef} />
      <ManageShiftDialog ref={manageShiftDialogRef} />
    </>
  );
}
