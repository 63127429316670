import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { DefaultError, UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import * as _ from 'lodash';
import { Dayjs } from 'dayjs';
import { UpdateDocumentValidationSchema } from '@/views/all-documents/manage/update-document-validation-schema';

export interface Request extends Partial<UpdateDocumentValidationSchema> {
  id: string;
  file?: File | null;
  isOnlyEditExpData?: boolean;
  progressCallBack?: (progressEvent: ProgressEvent) => void;
}

export type Options = Omit<UseMutationOptions<AxiosResponse, DefaultError, Request>, 'mutationKey' | 'mutationFn'>;

export const useUpdateDocumentMutation = (options?: Options) => {
  const queryClient = useQueryClient();
  const _mutation = async (value: Request): Promise<AxiosResponse<any>> => {
    const { file, documentType, status, dueDate, isOnlyEditExpData } = value;
    let parsedValue = {};
    
    if (isOnlyEditExpData) {
      parsedValue = {
        dueDate: value.dueDate
      }
    } else {
      parsedValue = {
        file,
        documentType,
        status,
        dueDate: dueDate !== '' ? (dueDate as Dayjs).format('YYYY-MM-DD'): '',
        therapistId: value.therapist ? JSON.parse(value.therapist).id : null,
        serviceId: value.service ? JSON.parse(value.service).id : null,
      };
    }
    const formDataValues = _.omitBy(parsedValue, (v) => _.isNil(v) || v === '');
    let fd = new FormData();

    const formDataEntries = Object.entries(formDataValues);
    for (const [prop, value] of formDataEntries) {
      fd.append(prop, value as any);
    }

    const axiosResponse = await axiosInterceptorInstance.patch(`documents/${value.id}`, fd, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: '*/*',
      },
      onUploadProgress: (progressEvent: any) => {
        value.progressCallBack && value.progressCallBack(progressEvent);
      },
    });
    return axiosResponse;
  };

  return useMutation({
    mutationKey: ['documents'],
    onSettled: ((data, error) => {
      if (!error) { 
        queryClient.invalidateQueries({ queryKey: ['documents'] });
        queryClient.invalidateQueries({ queryKey: ['activity-logs'] });
      }
    }),
    mutationFn: _mutation,
    ...options,
  });
};
