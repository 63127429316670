import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { ShiftDetails } from '@/views/all-shifts/interfaces/shift.interface';
import { DefaultError, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { transformDateToTime } from '@/lib/utils';

export type Options = Omit<UseQueryOptions<unknown, DefaultError, ShiftDetails | null>, 'queryKey' | 'queryFn'>;

export const useShiftQuery = (id?: string, options?: Options) => {
  const _query = async (): Promise<ShiftDetails | null> => {
    if (id) {
      const { data } = await axiosInterceptorInstance.get(`shifts/${id}`);
      if (data) {
        if (data.startAt) {
          data.startTime = transformDateToTime(data.startAt);
        }
        if (data.endAt) {
          data.endTime = transformDateToTime(data.endAt);
        }
        if (data.clockIn) {
          data.clockIn = transformDateToTime(data.clockIn);
        }
        if (data.clockOut) {
          data.clockOut = transformDateToTime(data.clockOut);
        }
      }
      return data;
    } else {
      return null;
    }
  };

  return useQuery({
    queryKey: ['shifts', id],
    queryFn: _query,
    refetchOnMount: false,
    ...options,
  });
};
