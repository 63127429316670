import { Button, Dialog } from '@/components/ui';
import {
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';

import { useTranslations } from 'next-intl';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { transformDateToViewDate } from '@/lib/utils';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage, Input } from '@/components/form';
import { DATE_MASK } from '@/common/constants';
import { useMask } from '@react-input/mask';

import { DATE_MMDDYYYY } from '@/common/constants';
import { z } from 'zod';

const updateAuthorizedToValidationSchema = z.object({
  authorizedTo: z
    .string()
    .min(1, { message: 'Forms.authorizedTo.required' })
    .regex(DATE_MMDDYYYY, { message: 'Forms.authorizedTo.invalid' }),
});

type UpdateAuthorizedToValidationSchema = z.infer<typeof updateAuthorizedToValidationSchema>;

interface Props {
  confirm: (authorizedTo: string) => void;
  cancel?: () => void;
}

export type ReinstateConfirmDialogRef = {
  open: (authorizedTo: string) => void;
};

const ReinstateConfirmDialog = forwardRef<ReinstateConfirmDialogRef, Props>(({ confirm, cancel }, ref) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const inputAuthorizedToRef = useMask({ ...DATE_MASK });
  const t = useTranslations();

  useImperativeHandle(ref, () => ({
    open: (authorizedTo) => {
      setIsOpen(true);
      form.setValue('authorizedTo', transformDateToViewDate(authorizedTo, false));
    },
  }));

  const form = useForm<UpdateAuthorizedToValidationSchema>({
    mode: 'onTouched',
    resolver: zodResolver(updateAuthorizedToValidationSchema),
  });

  const onSubmit: SubmitHandler<UpdateAuthorizedToValidationSchema> = async (
    value: UpdateAuthorizedToValidationSchema
  ) => {
    setIsOpen(false);
    confirm(value.authorizedTo as string);
  };

  return (
    <>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent onOpenAutoFocus={(e) => e.preventDefault()}>
          <DialogHeader>
            <DialogTitle className="flex flex-row items-center">{t('Common.reinstateServiceTitle')}</DialogTitle>
            <DialogDescription>{t('Common.confirmReinstateService')}</DialogDescription>
          </DialogHeader>

          <Form {...form}>
            <form className="mt-4" noValidate onSubmit={form.handleSubmit(onSubmit)}>
              <div className="relative max-w-80">
                <FormField
                  control={form.control}
                  name="authorizedTo"
                  render={({ field, fieldState }) => (
                    <FormItem>
                      <FormLabel isRequired>{t('Forms.authorizedTo.newAuthorizedToLabel')}</FormLabel>
                      <FormControl>
                        <Input
                          hasError={!!fieldState.error}
                          className="w-full"
                          {...field}
                          ref={inputAuthorizedToRef}
                          type="text"
                          placeholder={t('Forms.authorizedTo.placeholder')}
                        />
                      </FormControl>
                      <FormMessage className="absolute" />
                    </FormItem>
                  )}
                />
              </div>

              <DialogFooter className="mt-8">
                <DialogClose asChild>
                  <Button onClick={cancel} className="w-20" size="lg" type="button" variant="outline">
                    Cancel
                  </Button>
                </DialogClose>

                <Button type="submit" className="w-20" size="lg" variant="default">
                  Apply
                </Button>
              </DialogFooter>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
    </>
  );
});

ReinstateConfirmDialog.displayName = 'ReinstateConfirmDialog';

export { ReinstateConfirmDialog };
