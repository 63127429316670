import { UpdateServiceValidationSchema } from './../../../../views/all-services/manage/update-service-validation-schema';
import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { transformViewDateToApiDate } from '@/lib/utils';
import { Therapist } from '@/views/all-therapists/interfaces/therapists.interface';
import { DefaultError, UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query';
import * as _ from 'lodash';

type Request = {
  id: string;
  value: UpdateServiceValidationSchema;
};

export type Options = Omit<UseMutationOptions<string, DefaultError, Request>, 'mutationKey' | 'mutationFn'>;

export const useUpdateServiceMutation = (options?: Options) => {
  const queryClient = useQueryClient();
  const _mutation = async ({ id, value }: Request): Promise<string> => {
    const {title, programNumber, inPersonOnly, geofencing, frequency, numberOfHours: hours, authorizationNumber} = value;
    const parsedValue = {
      title,
      // address,
      programNumber: programNumber === 0 ? null : programNumber,
      geofencingOn: geofencing ? geofencing : false,
      inPersonOnly,
      frequency,
      authorizationNumber: authorizationNumber ?? null,
      hours,
      languages: value.languages.map((tag) => tag.label),
      therapistId: value.therapist ? (JSON.parse(value.therapist) as Therapist).id : '',
      authorizedTo: transformViewDateToApiDate(value.authorizedTo),
      authorizedFrom: value.authorizedFrom === '' ? null : transformViewDateToApiDate(value.authorizedFrom),
    }
    const body = _.omitBy(parsedValue, v => v === '');
    const { data } = await axiosInterceptorInstance.patch(`services/${id}`, body);
    queryClient.invalidateQueries({ queryKey: ['services', id] });
    return data;
  };

  return useMutation({
    mutationKey: ['services'],
    onSettled: ((data, error) => {
      if (!error) {
        queryClient.resetQueries({ queryKey: ['services'] });
        queryClient.invalidateQueries({ queryKey: ['clients'] });
        queryClient.invalidateQueries({ queryKey: ['shifts'] });
        queryClient.invalidateQueries({ queryKey: ['therapists'] });
        queryClient.invalidateQueries({ queryKey: ['activity-logs'] });
      }
    }),
    mutationFn: _mutation,
    ...options,
  });
};
