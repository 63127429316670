'use client';

import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
  TagItem,
  DatePicker,
} from '@/components/form';
import { useTranslations } from 'next-intl';
import { Button } from '@/components/ui';
import { ScrollArea, toast, ToastTypeEnums } from '@/components/common';
import { DialogFooter } from '@/components/ui/dialog';
import { useEffect, useState } from 'react';
import { cn } from '@/lib/utils';
import { useTherapistsQuery } from '@/hooks/fetchers/queries/therapists/useTherapistsQuery';
import { useClientsQuery } from '@/hooks/fetchers/queries/clients/useClientsQuery';
import { Therapist } from '@/views/all-therapists/interfaces/therapists.interface';
import { Client } from '@/views/all-clients/interfaces/clients.interface';
import { useServicesQuery } from '@/hooks/fetchers/queries/services/useServicesQuery';
import { Service } from '@/views/all-services/interfaces/services.interface';
import { useCreateDocumentViaTemplateMutation } from '@/hooks/fetchers/mutations/documents/useCreateDocumentViaTemplateMutation';
import { Document } from '../interfaces/document.interface';
import {
  createDocumentViaTemplateSchema,
  CreateDocumentViaTemplateSchema,
} from './create-document-via-template-validation-schema';
import { DOCUMENT_STATUS_TYPES, DOCUMENT_TYPE_TYPES } from '@/common/enums';

interface Props {
  onCancel: () => void;
  onCreate: (document: Document) => void;
}

export function CreateDocumentViaTemplateForm({ onCancel, onCreate }: Props) {
  const t = useTranslations();
  const [keywordClient, setKeywordClient] = useState('');
  const [keywordService, setKeywordService] = useState('');
  const [keywordTherapist, setKeywordTherapist] = useState('');

  const { data: therapists, isLoading: isLoadingTherapists } = useTherapistsQuery<TagItem[]>(
    {
      search: keywordTherapist,
      skip: 0,
      take: 200,
    },
    {
      enabled: true,
      select: (value: any): TagItem[] =>
        value.data.map((therapist: Therapist) => ({
          label: `${therapist.firstName} ${therapist.lastName}`,
          value: {
            id: therapist.id,
            firstName: therapist.firstName,
            lastName: therapist.lastName,
          },
        })),
    }
  );

  const { data: clients, isLoading: isLoadingClients } = useClientsQuery<TagItem[]>(
    {
      search: keywordClient,
      skip: 0,
      take: 200,
    },
    {
      enabled: true,
      select: (value: any): TagItem[] =>
        value.data.map((client: Client) => ({
          label: `${client.firstName} ${client.lastName}`,
          value: {
            id: client.id,
            firstName: client.firstName,
            lastName: client.lastName,
          },
        })),
    }
  );

  const { data: services, isLoading: isLoadingServices } = useServicesQuery<TagItem[]>(
    {
      search: keywordService,
      skip: 0,
      take: 200,
    },
    {
      enabled: true,
      select: (value: any): TagItem[] =>
        value.data.map((sr: Service) => ({
          label: sr.title,
          value: {
            id: sr.id,
            title: sr.title,
            serviceRole: sr.serviceRole,
            client: sr.client,
            authorizedTo: sr.authorizedTo,
            therapist: sr.therapist,
          },
        })),
    }
  );

  const { mutate: create, isPending: isCreating } = useCreateDocumentViaTemplateMutation({
    onSuccess: (data) => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
        description: t('Toasts.documentCreated'),
      });
      form.reset();
      onCreate(data);
    },
  });

  const form = useForm<CreateDocumentViaTemplateSchema>({
    mode: 'onTouched',
    resolver: zodResolver(createDocumentViaTemplateSchema),
    defaultValues: {
      therapist: '',
      client: '',
      service: '',
      dueDate: '',
      status: '',
      documentType: '',
    },
  });

  const serviceSelector = form.watch('service');

  useEffect(() => {
    const { service } = form.getValues();
    if (service) {
      const parsedService: Service = JSON.parse(service);
      if (parsedService.client) {
        const { id, firstName, lastName } = parsedService.client;
        form.setValue('client', JSON.stringify({ id, firstName, lastName }));
      }
      if (parsedService.therapist) {
        const { id, firstName, lastName } = parsedService.therapist;
        form.setValue('therapist', JSON.stringify({ id, firstName, lastName }));
      }
    }
  }, [serviceSelector]);

  const onSubmit: SubmitHandler<CreateDocumentViaTemplateSchema> = async (value: CreateDocumentViaTemplateSchema) => {
    create(value);
  };

  const onSearchByClients = (value: string) => {
    setKeywordClient(value);
  };

  const onSearchByServices = (value: string) => {
    setKeywordService(value);
  };

  const onSearchByTherapists = (value: string) => {
    setKeywordTherapist(value);
  };

  return (
    <Form {...form}>
      <form noValidate onSubmit={form.handleSubmit(onSubmit)}>
        <ScrollArea className="h-[calc(100vh-300px)] overflow-auto">
          <div className="ml-1 grid grid-cols-form-cols-2 gap-8">
            <div className="relative">
              <FormField
                control={form.control}
                name="service"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel isRequired>{t('Forms.service.label')}</FormLabel>
                    <FormControl>
                      <Select
                        onOpenChange={() => setKeywordService('')}
                        value={field.value}
                        onValueChange={field.onChange}
                      >
                        <FormControl>
                          <SelectTrigger className={cn('', !!fieldState.error && 'border-destructive')}>
                            <SelectValue
                              placeholder={isLoadingServices ? t('Common.loadingWait') : t('Forms.service.placeholder')}
                            />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent search onChangeSearch={onSearchByServices}>
                          {field.value && <SelectItem value={field.value}>{JSON.parse(field.value).title}</SelectItem>}

                          {services
                            ?.filter((item) => (field.value ? item.value.id !== JSON.parse(field.value).id : true))
                            .map((item) => (
                              <SelectItem key={item.value.id} value={JSON.stringify(item.value)}>
                                {item.label}
                              </SelectItem>
                            ))}
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage className="absolute" />
                  </FormItem>
                )}
              />
            </div>

            <div className="relative">
              <FormField
                control={form.control}
                name="therapist"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel isRequired>{t('Forms.therapist.label')}</FormLabel>
                    <FormControl>
                      <Select
                        onOpenChange={() => setKeywordTherapist('')}
                        value={field.value}
                        onValueChange={field.onChange}
                      >
                        <FormControl>
                          <SelectTrigger className={cn('', !!fieldState.error && 'border-destructive')}>
                            <SelectValue
                              placeholder={
                                isLoadingTherapists ? t('Common.loadingWait') : t('Forms.therapist.placeholder')
                              }
                            />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent search onChangeSearch={onSearchByTherapists}>
                          <SelectItem value={null as any}>None</SelectItem>
                          {field.value && field.value !== '' ? (
                            <SelectItem
                              value={field.value}
                            >{`${JSON.parse(field.value).firstName} ${JSON.parse(field.value).lastName}`}</SelectItem>
                          ) : null}

                          {therapists
                            ?.filter((item) => (field.value ? item.value.id !== JSON.parse(field.value).id : true))
                            .map((item) => (
                              <SelectItem key={item.value.id} value={JSON.stringify(item.value)}>
                                {item.label}
                              </SelectItem>
                            ))}
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage className="absolute" />
                  </FormItem>
                )}
              />
            </div>

            <div className="relative">
              <FormField
                control={form.control}
                name="client"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel isRequired>{t('Forms.client.label')}</FormLabel>
                    <FormControl>
                      <Select
                        onOpenChange={() => setKeywordClient('')}
                        value={field.value}
                        onValueChange={field.onChange}
                      >
                        <FormControl>
                          <SelectTrigger className={cn('', !!fieldState.error && 'border-destructive')}>
                            <SelectValue
                              placeholder={isLoadingClients ? t('Common.loadingWait') : t('Forms.client.placeholder')}
                            />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent search onChangeSearch={onSearchByClients}>
                          {field.value && (
                            <SelectItem
                              value={field.value}
                            >{`${JSON.parse(field.value).firstName} ${JSON.parse(field.value).lastName}`}</SelectItem>
                          )}

                          {clients
                            ?.filter((item) => (field.value ? item.value.id !== JSON.parse(field.value).id : true))
                            .map((item) => (
                              <SelectItem key={item.value.id} value={JSON.stringify(item.value)}>
                                {item.label}
                              </SelectItem>
                            ))}
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage className="absolute" />
                  </FormItem>
                )}
              />
            </div>

            <div className="relative">
              <FormField
                control={form.control}
                name="dueDate"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel>{t('Forms.dueDate.label')}</FormLabel>
                    <DatePicker disableDates="none" field={field} isError={fieldState.error} />
                    <FormMessage className="absolute" />
                  </FormItem>
                )}
              />
            </div>

            <div className="relative">
              <FormField
                control={form.control}
                name="status"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel isRequired>{t('Forms.status.label')}</FormLabel>
                    <FormControl>
                      <Select value={field.value?.toString()} onValueChange={field.onChange}>
                        <FormControl>
                          <SelectTrigger className={cn('', !!fieldState.error && 'border-destructive')}>
                            <SelectValue placeholder={t('Forms.status.placeholderSelector')} />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          <SelectItem value={DOCUMENT_STATUS_TYPES.Completed}>Completed</SelectItem>
                          <SelectItem value={DOCUMENT_STATUS_TYPES.Pending}>Pending</SelectItem>
                          <SelectItem value={DOCUMENT_STATUS_TYPES.Submitted}>Submitted</SelectItem>
                          <SelectItem value={DOCUMENT_STATUS_TYPES['Report due']}>Report Due</SelectItem>
                          <SelectItem value={DOCUMENT_STATUS_TYPES['Notes due']}>Notes Due</SelectItem>
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage className="absolute" />
                  </FormItem>
                )}
              />
            </div>

            <div className="relative">
              <FormField
                control={form.control}
                name="documentType"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel isRequired>{t('Forms.documentType.label')}</FormLabel>
                    <FormControl>
                      <Select value={field.value?.toString()} onValueChange={field.onChange}>
                        <FormControl>
                          <SelectTrigger className={cn('', !!fieldState.error && 'border-destructive')}>
                            <SelectValue placeholder={t('Forms.documentType.placeholderSelector')} />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          <SelectItem value={DOCUMENT_TYPE_TYPES.Progress}>{DOCUMENT_TYPE_TYPES.Progress}</SelectItem>
                          <SelectItem value={DOCUMENT_TYPE_TYPES.Discharge}>{DOCUMENT_TYPE_TYPES.Discharge}</SelectItem>
                          <SelectItem value={DOCUMENT_TYPE_TYPES.Assessment}>
                            {DOCUMENT_TYPE_TYPES.Assessment}
                          </SelectItem>
                          <SelectItem value={DOCUMENT_TYPE_TYPES.POS}>{DOCUMENT_TYPE_TYPES.POS}</SelectItem>
                          <SelectItem value={DOCUMENT_TYPE_TYPES.IFSP}>{DOCUMENT_TYPE_TYPES.IFSP}</SelectItem>
                          <SelectItem value={DOCUMENT_TYPE_TYPES.Other}>{DOCUMENT_TYPE_TYPES.Other}</SelectItem>
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage className="absolute" />
                  </FormItem>
                )}
              />
            </div>
          </div>
        </ScrollArea>

        <DialogFooter className="mt-10 flex h-20 flex-row items-center justify-end space-x-2 border-t border-t-gray-300">
          <Button onClick={onCancel} variant="ghost" type="button" size="lg" className="mt-5">
            {t('Buttons.cancel')}
          </Button>

          <Button type="submit" size="lg" className="mt-5" disabled={isCreating}>
            {t('Buttons.save')}
          </Button>
        </DialogFooter>
      </form>
    </Form>
  );
}
