import { ALLOWED_DOMAIN } from '@/common/constants';
import { validatePhone } from '@/components/form';
import { ROLE_TYPES } from '@/lib/RBAC/enums/role-types';
import { z } from 'zod';

export const manageTherapistValidationSchema = z
  .object({
    isOvertimeAllowed: z.boolean().default(false).optional(),
    firstName: z
      .string()
      .min(1, { message: 'Forms.firstName.required' })
      .min(2, { message: 'Forms.firstName.min' })
      .max(50, { message: 'Forms.firstName.max' }),
      // .regex(USER_NAME, { message: 'Forms.firstName.onlyAlphabetic' }),
    lastName: z
      .string()
      .min(1, { message: 'Forms.lastName.required' })
      .min(2, { message: 'Forms.lastName.min' })
      .max(50, { message: 'Forms.lastName.max' }),
      // .regex(USER_NAME, { message: 'Forms.lastName.onlyAlphabetic' }),
    email: z
      .string({ message: 'Forms.email.required' })
      .min(1, { message: 'Forms.email.required' })
      .max(100, { message: 'Forms.email.max' })
      .email('Forms.invalid')
      .refine((value) => value.endsWith(ALLOWED_DOMAIN), {
        message: 'Forms.email.wrongDomain',
      }),
    phoneNumber: z.string().min(1, { message: 'Forms.phoneNumber.required' }),
    languages: z.array(
      z.object({
        label: z.string(),
        value: z.any(),
      })
    ),
    role: z.string().min(1, { message: 'Forms.accessLevel.required' }),
    serviceRoleIdList: z.array(
      z.object({
        label: z.string(),
        value: z.any(),
      })
    ),
    supervisor: z.string(),
  })
  .refine((data) => {
    if ([ROLE_TYPES['Assistant Limited'], ROLE_TYPES.Assistant].includes(data.role as ROLE_TYPES)) {
      return data.supervisor && data.supervisor.trim().length > 0;
    }
    return true;
  }, {
    message: "Forms.supervisor.requiredForSpecialRole",
    path: ["supervisor"],
  })
  .superRefine((data, ctx) => {
    if (data.phoneNumber && data.phoneNumber.length > 0) {
      if (!validatePhone(data.phoneNumber)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['phoneNumber'],
          message: 'Forms.phoneNumber.invalid',
        });
      }
    }
  });

export type ManageTherapistValidationSchema = z.infer<typeof manageTherapistValidationSchema>;
