import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { DefaultError, UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query';

type Request = {
  id: string;
  note: string;
};

export type Options = Omit<UseMutationOptions<string, DefaultError, Request>, 'mutationKey' | 'mutationFn'>;

export const useSetOnHoldServiceMutation = (options?: Options) => {
  const queryClient = useQueryClient();
  const _mutation = async ({ id, note }: Request): Promise<string> => {
    const { data } = await axiosInterceptorInstance.patch(`services/${id}/set-on-hold`, { note });
    return data;
  };

  return useMutation({
    mutationKey: ['services', 'set-on-hold'],
    onSettled: (data, error) => {
      if (!error) {
        queryClient.resetQueries({ queryKey: ['services'] });
        queryClient.invalidateQueries({ queryKey: ['clients'] });
        queryClient.invalidateQueries({ queryKey: ['therapists'] });
        queryClient.invalidateQueries({ queryKey: ['activity-logs'] });
        queryClient.invalidateQueries({ queryKey: ['comments'] });
      }
    },
    mutationFn: _mutation,
    ...options,
  });
};
