import { transformViewDateToApiDate } from '@/lib/utils';
import { CreateServiceValidationSchema } from './../../../../views/all-services/manage/create-service-validation-schema';
import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { ServiceRole } from '@/views/service-roles/interfaces/service-role.interface';
import { DefaultError, UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query';
import * as _ from 'lodash';


export type Options = Omit<UseMutationOptions<string, DefaultError, CreateServiceValidationSchema>, 'mutationKey' | 'mutationFn'>;

export const useCreateServiceMutation = (options?: Options) => {
  const queryClient = useQueryClient();
  const _mutation = async (value: CreateServiceValidationSchema): Promise<string> => {
    const {title, programNumber, inPersonOnly, geofencing, numberOfHours: hours, frequency, authorizationNumber} = value;
    const parsedValue = {
      title,
      // address,
      programNumber,
      authorizationNumber,
      inPersonOnly,
      geofencingOn: geofencing ? geofencing : false,
      clientId: value.client ? JSON.parse(value.client).id : '',
      serviceRoleId: value.serviceRole ? (JSON.parse(value.serviceRole) as ServiceRole).id : '',
      frequency,
      hours,
      languages: value.languages.map((tag) => tag.label),
      guardianIds: value.guardians.map((tag) => tag.value.id),
      therapistId: value.therapist ? JSON.parse(value.therapist).id : '',
      authorizedFrom: transformViewDateToApiDate(value.authorizedFrom),
      authorizedTo: transformViewDateToApiDate(value.authorizedTo),
    }
    const body = _.omitBy(parsedValue, v => _.isNil(v) || v === '');
    const { data } = await axiosInterceptorInstance.post(`services`, body);
    return data;
  };

  return useMutation({
    mutationKey: ['services'],
    onSettled: ((data, error) => {
      if (!error) {
        queryClient.resetQueries({ queryKey: ['services'] });
        queryClient.resetQueries({ queryKey: ['activity-logs'] });
      }
    }),
    mutationFn: _mutation,
    ...options,
  });
};
